import React, { useState, useEffect } from "react";
import classes from "./InvoiceCard.css";
import WhiteCard from "../../../components/UI/WhiteCard/WhiteCard";
import TitledElement from "../../../components/UI/titledElement/titledElement";
import TextField from "@material-ui/core/TextField";
import SuggestionBox from "../../../components/UI/SuggestionBox/SuggestionBox";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "../../../axios";
import MutanabiButton from "../../../components/UI/MutanabiButton/MutanabiButton";

const InvoiceCard = React.memo(
  ({
    item,
    index,
    handleInvoiceNumberChange,
    handleCustomerSelection,
    handlecustomerText,
    handleAmountChange,
    handlePaymentMethodChange,
    paymentMethods,
    handleStatusMethodChange,
    handleDeliveryPersonChange,
    handlePrint,
    deleteItem,
    handlePacksChange,
    deliveryPersons,
    statusMethods,
    items,
    isChangingList,
    setItems
  }) => {
    var itms = [];
    itms = items;
    useEffect(() => {
      // console.log(item.fromExcell)
      if (item.fromExcell) {
        var updatedItems = [...items];
        var foundIndex;
        updatedItems.forEach((updateditem, index) => {
          //
          if (updateditem.invoiceId === item.invoiceId) {
            foundIndex = index;
            return;
          }
        });
        axios
          .get(
            `/invoices/customer/search?text=${item.customerName}&city=${item.address}`
          )
          .then(customer => {
            const updatedItem = {
              invoiceNumber: item.InvoiceNumber ? item.InvoiceNumber : "",
              invoiceId: item.invoiceId,
              status: item.status,
              paymentMethod: items.paymentMethod ? items.paymentMethod : "",
              invoiceSystem: "mersal",
              customer: customer.data,
              customerName: customer.data.name,
              amount: item.amount ? item.amount : "",
              deliveryPerson: "mersal",
              IssuanceDate: new Date(),
              text: customer.data.name,
              packsNumber: item.packsNumber
            };
            // console.log(customer.data)
            updatedItems[foundIndex] = updatedItem;
            // console.log(updatedItems[foundIndex])
            console.log(updatedItems);
            setItems(m => {
              console.log(m);
              m[foundIndex].invoiceNumber = item.InvoiceNumber
                ? item.InvoiceNumber
                : m[foundIndex].invoiceNumber;
              // m[foundIndex].invoiceId = m[foundIndex].invoiceId
              // m[foundIndex].status = m[foundIndex].status
              m[foundIndex].paymentMethod = item.paymentMethod
                ? item.paymentMethod
                : m[foundIndex].paymentMethod;
              // m[foundIndex].invoiceSystem = "mersal"
              // m[foundIndex].deliveryPerson = "mersal"
              m[foundIndex].IssuanceDate = new Date();

              m[foundIndex].customer = customer.data;
              m[foundIndex].amount = item.amount
                ? item.amount
                : m[foundIndex].amount;
              m[foundIndex].packsNumber = item.packsNumber
                ? item.packsNumber
                : m[foundIndex].packsNumber;
              console.log(m);
              return m;
            });
          });
        return;
      }
      axios
        .get(`/invoices/invoice?invoiceId=${item.invoiceId}`)
        .then(result => {
          isChangingList[item.invoiceId] = true;

          if (result.data.notFound == 1) {
            return;
          }

          var updatedItems = [...items];
          var foundIndex;
          updatedItems.forEach((item, index) => {
            //
            if (item.invoiceId === result.data.result.invoiceId) {
              foundIndex = index;
              return;
            }
          });
          const updatedItem = {
            invoiceNumber: result.data.result.invoiceNumber
              ? result.data.result.invoiceNumber
              : "",
            invoiceId: item.invoiceId,
            status: updatedItems[foundIndex].status,
            paymentMethod: result.data.result.paymentMethod
              ? result.data.result.paymentMethod
              : "",
            invoiceSystem:
              item.invoiceId.substring(0, 6) == "105318" ? "ct" : "mersal",
            customer: result.data.result.customer,
            customerName: result.data.result.customer
              ? result.data.result.customer.name
              : item.customerName
              ? item.customerName
              : "",
            amount: result.data.result.amount ? result.data.result.amount : "",
            packsNumber: result.data.result.packsNumber
              ? result.data.result.packsNumber
              : "",
            deliveryPerson: result.data.result.deliveryPerson
              ? result.data.result.deliveryPerson
              : "",
            IssuanceDate: result.data.result.IssuanceDate
              ? result.data.result.IssuanceDate
              : new Date(),
            text: result.data.result.customer
              ? result.data.result.customer.name
              : item.customerName
              ? item.customerName
              : "",
            issuer: result.data.result.issuer
          };
          updatedItems[foundIndex] = updatedItem;
          setItems(updatedItems);
        });
    }, []);
    return (
      <div>
        <WhiteCard>
          <div className={classes.Container}>
            <div className={classes.CardLabel}>
              <div className={classes.number}>
                <p>{index + 1}</p>
              </div>
              <TitledElement
                title="ID"
                subtitle={item.invoiceId}
              ></TitledElement>
            </div>

            <div className={classes.InvoicesCard}>
              <div className={classes.SuggestionBox}>
                <TitledElement
                  //title="Customer"
                  className={classes.SuggestionBox}
                >
                  <SuggestionBox
                    className={classes.SuggestionBox}
                    id={`box${item.invoiceId}`}
                    onCustomerSelection={customer =>
                      handleCustomerSelection(customer, index)
                    }
                    text={item.text}
                    setText={text => handlecustomerText(text, index)}
                  ></SuggestionBox>
                </TitledElement>
              </div>
              <TitledElement

              //title="Invoice no"
              >
                <TextField
                  className={classes.InvoiceNumber}
                  id="standard-required"
                  label="Invoice number"
                  margin="normal"
                  type="number"
                  //// required
                  autoComplete="off"
                  value={item.invoiceNumber}
                  onChange={event => handleInvoiceNumberChange(event, index)}
                />
              </TitledElement>

              <TitledElement
              //title="Amount"
              >
                <TextField
                  id="standard-required-Amount"
                  label="Amount"
                  margin="normal"
                  className={classes.SubTextField}
                  onChange={event => handleAmountChange(event, index, itms)}
                  value={item.amount}
                  type="number"
                  autoComplete="off"
                />
              </TitledElement>
              <TitledElement
              //title="Payment method"
              >
                <TextField
                  id="standard-required-Payment"
                  select
                  label="Payment method"
                  margin="normal"
                  type="text"
                  className={classes.SubTextField}
                  // required
                  value={item.paymentMethod}
                  onChange={event => handlePaymentMethodChange(event, index)}
                  helperText="Please select payment method"
                  //   onChange={handleCityInput}
                >
                  {paymentMethods.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </TitledElement>

              <TitledElement
              //title="Invoice status"
              >
                <TextField
                  id="standard-required"
                  select
                  label="Invoice status"
                  margin="normal"
                  type="number"
                  className={classes.SubTextField}
                  //   required
                  value={item.status}
                  onChange={event => handleStatusMethodChange(event, index)}
                  helperText="Please select invoice status"
                  //   onChange={handleCityInput}
                >
                  {statusMethods.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </TitledElement>
              <TitledElement
              //title="Payment method"
              >
                <TextField
                  id="standard-required-delivery"
                  select
                  label="Delivery person"
                  margin="normal"
                  type="text"
                  className={classes.SubTextField}
                  // required
                  value={item.deliveryPerson}
                  onChange={event => handleDeliveryPersonChange(event, index)}
                  helperText="Please select delivery person"
                  //   onChange={handleCityInput}
                >
                  {deliveryPersons.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </TitledElement>
              <TitledElement
              //title="Amount"
              >
                <TextField
                  id="standard-required-packs"
                  label="Number of packs"
                  margin="normal"
                  className={classes.SubTextField}
                  onChange={event => handlePacksChange(event, index)}
                  value={item.packsNumber}
                  type="number"
                  autoComplete="off"
                />
              </TitledElement>
              <MutanabiButton
                width={200}
                height={50}
                click={() => handlePrint(index)}
              >
                Print
              </MutanabiButton>
              <MutanabiButton
                color="red"
                width={200}
                height={50}
                click={e => {
                  if (
                    window.confirm("Are you sure you wish to delete this item?")
                  )
                    deleteItem(index);
                }}
              >
                Delete
              </MutanabiButton>
            </div>
          </div>
        </WhiteCard>
      </div>
    );
  },
  (prevProps, nextProps) => {
    // true don't render
    // console.log(prevProps.item != nextProps.item)
    // console.log(prevProps)
    // console.log(nextProps)

    const isChanged = !prevProps.isChangingList[prevProps.item.invoiceId];
    nextProps.isChangingList[nextProps.item.invoiceId] = false;
    // const nItems = [...nextProps.items];
    // nItems[0].isChanged = false;
    // nextProps.setItems(nItems);
    return isChanged;
  }
);

export default InvoiceCard;
