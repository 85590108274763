

const options = {
    print: false,
    filterType: "checkbox",
    responsive: "scroll",
    textLabels: {
      body: {
        noMatch: "no matchs"
      }
    },
    customSort: (data, colIndex, order) => {
      if (colIndex === 9) {
        return data.sort((a, b) => {
          if (order === "desc") {
            return new Date(a.data[colIndex]) - new Date(b.data[colIndex]);
          } else {
            return new Date(b.data[colIndex]) - new Date(a.data[colIndex]);
          }
        });
      } else {
        return data.sort((a, b) => {
          return (
            (a.data[colIndex] < b.data[colIndex] ? -1 : 1) *
            (order === "desc" ? 1 : -1)
          );
        });
      }
    },
    onDownload:(buildHead, buildBody, columns, data) =>
    {
      var csvString = ""
      columns.forEach((column,index) => {
        csvString += index<columns.length - 1?column.label+",":column.label+"\n"
      });
      data.forEach(row=>{
        var subString = ""
        row.data.forEach((element,index2)=>{
         
          var l = element?element.toString():''
          csvString += index2<row.data.length -1?l+ ",":l+"\n"
        })
        csvString += subString
      })
      var BOM = "\uFEFF"; 
      return BOM+csvString
    }
  };
  

  
  
  exports.options = options 
  // exports.columns = columns