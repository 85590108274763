import React from 'react';
import classes from './WhiteCard.css'

const WhiteCard = ({children,backgroundColor})=>{
    return(
        <div className={classes.Card} style={{backgroundColor:backgroundColor?backgroundColor:"white"}}>
        {children}
        </div>
    )
}

export default WhiteCard