const routes = {
  dashboard: {
    path: "/dashboard",
    name: "Dashboard"
  },
  stock: {
    path: "/stock",
    name: "Stock"
  },
  customers:{
    path:"/customers",
    name:"Customers"
  },
  itemSearch:{
    path:"/itemSearch",
    name:"Item Search"
  },
  validator:{
    path:"/validator",
    name:"Item Validation"
  },
  login:{
    path:"/login",
    name:"Login"
  },
  signup:{
    path:"/signup",
    name:"Signup"
  },
  settings:{
    path:"/settings",
    name:"QR Settings"
  },
  labelsGenerator:{
    path:"/labelsGenerator",
    name:"Labels Generator"
  },
  invoicesReports:{
    path:"/invoicesReports",
    name:"Invoices Reports"
  },
  updateInvoiceStatus:{
    path:"/updateInvoiceStatus",
    name:"Update Invoices Status"
  }
};

export default routes;
