import React, { useState, useEffect } from "react";
import classes from "./LabelsGenerator.css";
import WhiteCard from "../../../components/UI/WhiteCard/WhiteCard";
import MutanabiButton from "../../../components/UI/MutanabiButton/MutanabiButton";
import axios from "../../../axios";
import naxios from "axios";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Grid, Input, Select } from "react-spreadsheet-grid";
import AsyncSelect from "react-select/async";
import set from "lodash.set";
const helpers = require("../../../functions/helperFunctions");
const qs = require("querystring");
var QRCode = require("qrcode");
var stringSimilarity = require("string-similarity");

const LabelsGenerator = ({ auth }) => {
  var todayString = "";
  var today = new Date();
  var dd = today.getDate();

  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }
  todayString = mm + "/" + dd + "/" + yyyy;

  const [latestItems, setlatestItems] = useState([]);
  const asyncPrint = items => {
    const waitFor = ms => new Promise(r => setTimeout(r, ms));
    items.forEach(async item => {
      const city = item.customer.city
        ? item.customer.city
        : item.customer.address;
      printLabel(
        item.invoiceId,
        item.invoiceNumber,
        item.customer.label,
        item.packsNumber,
        city,
        item.paymentMethod
      );
      await waitFor(100);
      // console.log("print")
    });
  };

  const printLabel = (
    invoiceId,
    invoiceNumber,
    customerName,
    packsNumber,
    city,
    paymentMethod
  ) => {
    QRCode.toDataURL(invoiceId, { type: "terminal" }, function(err, url) {
      // console.log(invoiceNumber)
      // console.log(customerName)
      // console.log(packsNumber)
      // console.log(city)
      // console.log(paymentMethod)

      const requestBody = {
        printerName: "DYMO LabelWriter 450",
        labelXml: `<?xml version="1.0" encoding="utf-8"?>
      <DieCutLabel Version="8.0" Units="twips" MediaType="Default">
      <PaperOrientation>Landscape</PaperOrientation>
      <Id>Address</Id>
      <PaperName>30252 Address</PaperName>
      <DrawCommands>
      <RoundRectangle X="0" Y="0" Width="1581" Height="5040" Rx="270" Ry="270"/>
      </DrawCommands>
      <ObjectInfo>
      <AddressObject>
      <Name>Address</Name>
      <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
      <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
      <LinkedObjectName></LinkedObjectName>
      <Rotation>Rotation0</Rotation>
      <IsMirrored>False</IsMirrored>
      <IsVariable>True</IsVariable>
      <HorizontalAlignment>Right</HorizontalAlignment>
      <VerticalAlignment>Middle</VerticalAlignment>
      <TextFitMode>ShrinkToFit</TextFitMode>
      <UseFullFontHeight>True</UseFullFontHeight>
      <Verticalized>False</Verticalized>
      <StyledText>
      <Element>
      <String>مستودع ادويه المتنبي</String>
      <Attributes>
      <Font Family="Geeza Pro" Size="14" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
      <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
      </Attributes>
      </Element>
      </StyledText>
      <ShowBarcodeFor9DigitZipOnly>False</ShowBarcodeFor9DigitZipOnly>
      <BarcodePosition>Suppress</BarcodePosition>
      <LineFonts>
      <Font Family="Geeza Pro" Size="13" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
      </LineFonts>
      </AddressObject>
      <Bounds X="3087.859" Y="57.59995" Width="1759.256" Height="387.8125"/>
      </ObjectInfo>
      <ObjectInfo>
      <ImageObject>
      <Name>Image</Name>
      <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
      <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
      <LinkedObjectName></LinkedObjectName>
      <Rotation>Rotation0</Rotation>
      <IsMirrored>False</IsMirrored>
      <IsVariable>False</IsVariable>
      <Image>${url.split(",")[1]}</Image>
      <ScaleMode>Uniform</ScaleMode>
      <BorderWidth>0</BorderWidth>
      <BorderColor Alpha="255" Red="0" Green="0" Blue="0"/>
      <HorizontalAlignment>Center</HorizontalAlignment>
      <VerticalAlignment>Center</VerticalAlignment>
      </ImageObject>
      <Bounds X="331.2" Y="57.59995" Width="1002.075" Height="1250.234"/>
      </ObjectInfo>
      <ObjectInfo>
      <TextObject>
      <Name>TEXT_1</Name>
      <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
      <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
      <LinkedObjectName></LinkedObjectName>
      <Rotation>Rotation0</Rotation>
      <IsMirrored>False</IsMirrored>
      <IsVariable>False</IsVariable>
      <HorizontalAlignment>Right</HorizontalAlignment>
      <VerticalAlignment>Middle</VerticalAlignment>
      <TextFitMode>ShrinkToFit</TextFitMode>
      <UseFullFontHeight>True</UseFullFontHeight>
      <Verticalized>False</Verticalized>
      <StyledText>
      <Element>
      <String>المستلم</String>
      <Attributes>
      <Font Family="Geeza Pro" Size="13" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
      <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
      </Attributes>
      </Element>
      <Element>
      <String>: </String>
      <Attributes>
      <Font Family="Lucida Grande" Size="13" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
      <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
      </Attributes>
      </Element>
      <Element>
      <String>${customerName}</String>
      <Attributes>
      <Font Family="Geeza Pro" Size="13" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
      <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
      </Attributes>
      </Element>
      </StyledText>
      </TextObject>
      <Bounds X="1482.422" Y="407.8125" Width="3434.844" Height="395.625"/>
      </ObjectInfo>
      <ObjectInfo>
      <TextObject>
      <Name>TEXT_2</Name>
      <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
      <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
      <LinkedObjectName></LinkedObjectName>
      <Rotation>Rotation0</Rotation>
      <IsMirrored>False</IsMirrored>
      <IsVariable>False</IsVariable>
      <HorizontalAlignment>Right</HorizontalAlignment>
      <VerticalAlignment>Middle</VerticalAlignment>
      <TextFitMode>ShrinkToFit</TextFitMode>
      <UseFullFontHeight>True</UseFullFontHeight>
      <Verticalized>False</Verticalized>
      <StyledText>
      <Element>
      <String>عدد الطرود</String>
      <Attributes>
      <Font Family="Geeza Pro" Size="13" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
      <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
      </Attributes>
      </Element>
      <Element>
      <String>:</String>
      <Attributes>
      <Font Family="Lucida Grande" Size="13" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
      <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
      </Attributes>
      </Element>
      <Element>
      <String>${packsNumber}</String>
      <Attributes>
      <Font Family="Geeza Pro" Size="13" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
      <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
      </Attributes>
      </Element>
      </StyledText>
      </TextObject>
      <Bounds X="3315.709" Y="788.75" Width="1499.062" Height="382.9688"/>
      </ObjectInfo>
      <ObjectInfo>
      <TextObject>
      <Name>TEXT_3</Name>
      <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
      <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
      <LinkedObjectName></LinkedObjectName>
      <Rotation>Rotation0</Rotation>
      <IsMirrored>False</IsMirrored>
      <IsVariable>False</IsVariable>
      <HorizontalAlignment>Left</HorizontalAlignment>
      <VerticalAlignment>Middle</VerticalAlignment>
      <TextFitMode>ShrinkToFit</TextFitMode>
      <UseFullFontHeight>True</UseFullFontHeight>
      <Verticalized>False</Verticalized>
      <StyledText>
      <Element>
      <String>${invoiceNumber}</String>
      <Attributes>
      <Font Family="Helvetica" Size="13" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
      <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
      </Attributes>
      </Element>
      </StyledText>
      </TextObject>
      <Bounds X="331.2" Y="1168.191" Width="1037.188" Height="324.6094"/>
      </ObjectInfo>
      <ObjectInfo>
      <TextObject>
      <Name>TEXT_4</Name>
      <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
      <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
      <LinkedObjectName></LinkedObjectName>
      <Rotation>Rotation0</Rotation>
      <IsMirrored>False</IsMirrored>
      <IsVariable>False</IsVariable>
      <HorizontalAlignment>Right</HorizontalAlignment>
      <VerticalAlignment>Middle</VerticalAlignment>
      <TextFitMode>ShrinkToFit</TextFitMode>
      <UseFullFontHeight>True</UseFullFontHeight>
      <Verticalized>False</Verticalized>
      <StyledText>
      <Element>
      <String>المدينه</String>
      <Attributes>
      <Font Family="Geeza Pro" Size="13" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
      <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
      </Attributes>
      </Element>
      <Element>
      <String>:</String>
      <Attributes>
      <Font Family="Lucida Grande" Size="13" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
      <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
      </Attributes>
      </Element>
      <Element>
      <String>${city}</String>
      <Attributes>
      <Font Family="Geeza Pro" Size="13" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
      <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
      </Attributes>
      </Element>
      </StyledText>
      </TextObject>
      <Bounds X="1717.059" Y="811.1719" Width="1392.344" Height="336.0156"/>
      </ObjectInfo>
      <ObjectInfo>
      <TextObject>
      <Name>TEXT_5</Name>
      <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
      <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
      <LinkedObjectName></LinkedObjectName>
      <Rotation>Rotation0</Rotation>
      <IsMirrored>False</IsMirrored>
      <IsVariable>False</IsVariable>
      <HorizontalAlignment>Right</HorizontalAlignment>
      <VerticalAlignment>Middle</VerticalAlignment>
      <TextFitMode>ShrinkToFit</TextFitMode>
      <UseFullFontHeight>True</UseFullFontHeight>
      <Verticalized>False</Verticalized>
      <StyledText>
      <Element>
      <String>طريقه الدفع</String>
      <Attributes>
      <Font Family="Geeza Pro" Size="13" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
      <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
      </Attributes>
      </Element>
      <Element>
      <String>: </String>
      <Attributes>
      <Font Family="Lucida Grande" Size="13" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
      <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
      </Attributes>
      </Element>
      <Element>
      <String>${
        paymentMethods.find(method => method.value == paymentMethod).name
      }</String>
      <Attributes>
      <Font Family="Geeza Pro" Size="13" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
      <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
      </Attributes>
      </Element>
      </StyledText>
      </TextObject>
      <Bounds X="1685.547" Y="1183.906" Width="3125.312" Height="308.8938"/>
      </ObjectInfo>
      <ObjectInfo>
      <TextObject>
      <Name>TEXT</Name>
      <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
      <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
      <LinkedObjectName></LinkedObjectName>
      <Rotation>Rotation0</Rotation>
      <IsMirrored>False</IsMirrored>
      <IsVariable>False</IsVariable>
      <HorizontalAlignment>Left</HorizontalAlignment>
      <VerticalAlignment>Middle</VerticalAlignment>
      <TextFitMode>ShrinkToFit</TextFitMode>
      <UseFullFontHeight>True</UseFullFontHeight>
      <Verticalized>False</Verticalized>
      <StyledText>
      <Element>
      <String>${todayString}</String>
      <Attributes>
      <Font Family="Lucida Grande" Size="13" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
      <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
      </Attributes>
      </Element>
      </StyledText>
      </TextObject>
      <Bounds X="1539.012" Y="57.59995" Width="1472.344" Height="367.1875"/>
      </ObjectInfo>
      </DieCutLabel>
      `,
        printParamsXml: "",
        labelSetXml: ""
      };

      console.log(requestBody.labelXml);

      const config = {
        headers: {
          Accept: "*/*",
          "Content-Type": "application/x-www-form-urlencoded",
          Connection: "keep-alive"
        }
      };

      naxios
        .post(
          "https://localhost:41951/DYMO/DLS/Printing/PrintLabel",
          qs.stringify(requestBody),
          config
        )
        .then(result => {
          // Do somthing
        })
        .catch(err => {
          console.log(err);
          // Do somthing
        });
    });
  };

  const paymentMethods = [
    {
      id: 1,
      value: "inAccount",
      name: "بالحساب"
    },
    {
      id: 2,
      value: "cashOnDelivery",
      name: "نقدا عند التسليم"
    },
    {
      id: 3,
      value: "chx",
      name: "شك"
    }
  ];
  const statusMethods = [
    {
      id: 1,
      value: "issued",
      name: "Issued"
    },
    {
      id: 2,
      value: "ofd",
      name: "Out for delivery"
    },
    {
      id: 3,
      value: "delivered",
      name: "Delivered"
    },
    {
      id: 4,
      value: "returned",
      name: "Returned"
    }
  ];
  const deliveryPersons = [
    {
      id: 1,
      value: "mersal",
      name: "مرسال"
    },
    {
      id: 2,
      value: "nidal",
      name: "نضال"
    },
    {
      id: 3,
      value: "raaed",
      name: "رائد"
    },
    { id: 4, value: "fadi", name: "فادي" },
    {
      id: 5,
      value: "fadiN",
      name: "فادي نعمة"
    },
    {
      id: 6,
      value: "muhanned",
      name: "مهند"
    },
    {
      id: 7,
      value: "motaz",
      name: "معتز"
    },
    {
      id: 8,
      value: "hussein",
      name: "حسين"
    },
    {
      id: 9,
      value: "murad",
      name: "مراد"
    },
    {
      id: 10,
      value: "mustafa",
      name: "مصطفى"
    },
    {
      id: 11,
      value: "maha",
      name: "مها"
    },
    {
      id: 12,
      value: "rima",
      name: "ريما"
    },
    {
      id: 13,
      value: "ahmed",
      name: "احمد"
    },
    {
      id: 14,
      value: "hadeel",
      name: "هديل"
    },
    {
      id: 15,
      value: "mai",
      name: "مي"
    },
    {
      id: 16,
      value: "hamzah",
      name: "حمزة"
    },
    {
      id: 17,
      value: "waael",
      name: "وائل"
    },
    {
      id: 18,
      value: "tamara",
      name: "تمارا"
    },
    {
      id: 19,
      value: "anmar",
      name: "انمار"
    },
    {
      id: 21,
      value: "eyad",
      name: "إياد"
    },
    {
      id: 21,
      value: "mohammed",
      name: "محمد"
    },
    {
      id: 21,
      value: "mamdouh",
      name: "ممدوح"
    },
    {
      id: 20,
      value: "others",
      name: "Others"
    }
  ];

  const [isDone, setIsDone] = useState(false);
  const [paymentMethod, setpaymentMethod] = useState("");
  const [isInvoiceAuto, setIsInvoiceAuto] = useState(true);
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setDefaultInvoiceNumber();
  }, []);

  const setDefaultInvoiceNumber = () => {
    axios.get(`/invoices/latest`).then(res => {
      setInvoiceNumber(res.data["invoiceNumber"]);
    });
  };
  const handleChange = event => {
    setpaymentMethod(event.target.value);
  };
  const resetForm = () => {
    setRows(defaultRows);
  };
  const submitHandler = async (event, x) => {
    event.preventDefault();
    setIsLoading(true);
    var finaldata = [];
    rows.forEach(row => {
      if (row.invoiceNumber !== "") {
        // console.log(row);
        const newInvoiceData = {
          invoiceNumber: row.invoiceNumber,
          invoiceId: `105308${new Date().getFullYear()}${+row.invoiceNumber}`,
          status:
            row.invoiceStatus !== ""
              ? statusMethods.find(method => method.id === row.invoiceStatus)
                  .value
              : "",
          paymentMethod:
            row.paymentMethodvalue != ""
              ? paymentMethods.find(
                  method => method.id == row.paymentMethodvalue
                ).value
              : "",
          invoiceSystem: "ct",
          customer: row.customer.id,
          customerName: row.customer.label,
          amount: row.amount,
          IssuanceDate: new Date(),
          issuer: auth.user.id,
          packsNumber: row.packsNumber,
          deliveryPerson:
            row.deliveryPerson != ""
              ? deliveryPersons.find(method => method.id == row.deliveryPerson)
                  .value
              : "",
          events: [
            {
              date: new Date(),
              status: statusMethods.find(
                method => method.id == row.invoiceStatus
              ).value,
              modifier: auth.user.id
            }
          ]
        };
        finaldata.push(newInvoiceData);
      }
    });
    // setInvoiceData(newInvoiceData);
    finaldata.map((data, index) => {
      data.customer = rows[index].customer;
    });
    var validationData = finaldata.map(item => item.invoiceId);
    var checkResults = await (
      await axios.post("/invoices/validate", validationData)
    ).data;
    console.log(checkResults);
    if (checkResults.length > 0) {
      console.log("1");
      var alertBody =
        "There are invoices numbers that are already in system please check them at \n";
      checkResults.forEach(resultItem => {
        alertBody = alertBody + `row ${resultItem.index + 1} \n`;
      });
      alert(alertBody);
      setIsLoading(false);
      return;
    }
    console.log("2");
    setIsLoading(false);
    // return
    // console.log(finaldata)
    // asyncPrint(finaldata)
    axios
      .post("/invoices/create", finaldata)
      .then(res => {
        // console.log(res.status)
        setIsLoading(false);
        if (res.data.error) {
          alert(`Error: ${res.data.error} please check it`);
          return;
        }
        setIsDone(true);

        setlatestItems(finaldata);
        if (x == 2) {
          const d = helpers.replecateArray(finaldata);
          asyncPrint(d);
        } else {
          asyncPrint(finaldata);
        }
        // printLabel(newInvoiceData);
      })
      .catch(err => {
        console.log(err);
        setIsLoading(false);
        alert("error occured");
      });
  };

  const autoInvoiceHandler = () => {
    if (isDone) {
      return;
    }
    setDefaultInvoiceNumber();
    setIsInvoiceAuto(!isInvoiceAuto);
  };

  const handleNewInvoice = () => {
    resetForm();
    setIsDone(false);
  };

  const submitButton = isDone ? (
    <div></div>
  ) : (
    <div>
      <div
        className={classes.Buttons}
        onClick={event => submitHandler(event, 1)}
      >
        <MutanabiButton isLoading={isLoading}>Generate</MutanabiButton>
      </div>
      <div
        className={classes.Buttons}
        onClick={event => submitHandler(event, 2)}
      >
        <MutanabiButton isLoading={isLoading}>Generate X 2</MutanabiButton>
      </div>
    </div>
  );

  const postButtons = isDone ? (
    <div className={classes.Buttons}>
      <MutanabiButton
        isLoading={isLoading}
        click={() => asyncPrint(latestItems)}
      >
        Reprint labels
      </MutanabiButton>
      <MutanabiButton isLoading={isLoading} click={handleNewInvoice}>
        New invoice
      </MutanabiButton>
    </div>
  ) : (
    <div></div>
  );

  var defaultRows = [];
  for (let index = 1; index < 19; index++) {
    const element = {
      id: index,
      invoiceNumber: "",
      invoiceNumberRef: React.createRef(),
      paymentMethodvalue: 1,
      paymentMethodRef: React.createRef(),
      customer: "",
      customerRef: React.createRef(),
      customerName: null,
      amount: "",
      amountRef: React.createRef(),
      invoiceStatus: 2,
      invoiceStatusRef: React.createRef(),
      deliveryPerson: "",
      deliveryPersonRef: React.createRef(),
      packsNumber: "",
      packsNumberRef: React.createRef()
    };
    defaultRows.push(element);
  }

  const [rows, setRows] = useState(defaultRows);
  const [blurCurrentFocus, setBlurCurrentFocus] = useState(true);
  const onFieldChange = (rowId, field, value) => {
    const modifiedRows = [].concat(rows);
    const row = modifiedRows.find(row => {
      return row.id === rowId;
    });
    set(row, field, value);
    setRows(modifiedRows);
    setBlurCurrentFocus(true);
  };

  const onInputFieldChange = (rowId, field, value, ref, active, focus) => {
    const modifiedRows = [].concat(rows);
    const row = modifiedRows.find(row => {
      return row.id === rowId;
    });
    set(row, field, value);
    setRows(modifiedRows);
  };

  const onKeyDownInputField = (key, ref, active, focus, isMultiple) => {
    if (
      key == "ArrowRight" ||
      key == "ArrowLeft" ||
      (key == "ArrowDown" && !isMultiple) ||
      (key == "ArrowUp" && !isMultiple) ||
      key == "Tab"
    ) {
      ref.current.blur();
    }
  };

  const moveCaretAtEnd = e => {
    var temp_value = e.target.value;
    e.target.value = "";
    e.target.value = temp_value;
  };

  const removeLastCharachter = (str, a, b) => {
    if (str.substring(str.length - 1) == a) {
      str = str.substring(0, str.length - 1) + b;
    }
    return str;
  };

  const loadOptions = (inputValue, callback) => {
    inputValue = inputValue.replace("صيدليه", "صيدلية");
    var splitValues = inputValue.split(" ");
    splitValues = splitValues.map(val => {
      return removeLastCharachter(val, "ه", "ة");
    });
    var inputValue = splitValues.join(" ");
    console.log(inputValue);
    // splitValues[1].
    axios
      .get(`/customer/search?text=${inputValue}`)
      .then(res => {
        var data = res.data;
        var sdata = data.map(item => {
          if (item.name.includes("/")) {
            const itemArr = item.name.split("/");
            const s1 = stringSimilarity.compareTwoStrings(
              inputValue,
              itemArr[0]
            );
            const s2 = stringSimilarity.compareTwoStrings(
              inputValue,
              itemArr[1]
            );
            const similarity = s1 > s2 ? s1 : s2;
            return { customer: item, similarity: similarity };
          } else {
            const similarity = stringSimilarity.compareTwoStrings(
              inputValue,
              item.name
            );
            return { customer: item, similarity: similarity };
          }
        });

        sdata.sort((a, b) =>
          b.similarity > a.similarity ? 1 : a.similarity > b.similarity ? -1 : 0
        );

        callback(
          sdata.map(customer => {
            return {
              label: customer.customer.name,
              value: customer.customer.id,
              city: customer.customer.city,
              address: customer.customer.address
            };
          })
        );
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <div className={classes.LabelsGenerator}>
      <h1>Labels generator</h1>
      <WhiteCard>
        {/* <div className={classes.NumberOfInvoices}>
          <input></input>
        </div> */}
        <div
          className={classes.Grid}
          style={{ height: defaultRows.length * 70 + 200 }}
        >
          <Grid
            columns={[
              {
                title: () => "#",
                value: (row, { focus, active }) => {
                  return <p>{row.id}</p>;
                },
                id: "id"
              },
              {
                title: () => "Customer",
                value: (row, { focus, active }) => {
                  if (active) {
                    setTimeout(() => {
                      // console.log(row);
                      row.customerRef.current.focus();
                    }, 50);
                  }
                  return (
                    <AsyncSelect
                      cacheOptions
                      loadOptions={loadOptions}
                      defaultOptions
                      ref={row.customerRef}
                      // onInputChange={val => {
                      //   onFieldChange(row.id, "customer", val);
                      // }}
                      onKeyDown={val =>
                        onKeyDownInputField(
                          val.key,
                          row.customerRef,
                          active,
                          focus,
                          true
                        )
                      }
                      // value={row.customerName}
                      // onChange={()=>{
                      //   return
                      // }}
                      onChange={val => {
                        onFieldChange(row.id, "customer", val);
                      }}
                      styles={{
                        control: base => ({
                          ...base,
                          border: 0,
                          boxShadow: 0,
                          "&:hover": {
                            border: 0
                          }
                        })
                      }}
                      // autoFocus={active}
                      isSearchable={true}
                      className={classes.CellItem}
                      focus={true}
                    />
                  );
                },
                id: "customer"
              },
              {
                title: () => "Invoice number",
                value: (row, { focus, active }) => {
                  if (active) {
                    setTimeout(() => {
                      row.invoiceNumberRef.current.focus();
                    }, 50);
                  }
                  return (
                    <input
                      type="number"
                      value={row.invoiceNumber}
                      className={classes.CellItem}
                      // onFieldChange(row.id, "invoiceNumber", val)
                      onChange={val =>
                        onInputFieldChange(
                          row.id,
                          "invoiceNumber",
                          val.target.value,
                          row.invoiceNumberRef,
                          active,
                          focus
                        )
                      }
                      ref={row.invoiceNumberRef}
                      onFocus={moveCaretAtEnd}
                      onKeyDown={val =>
                        onKeyDownInputField(
                          val.key,
                          row.invoiceNumberRef,
                          active,
                          focus
                        )
                      }
                    ></input>
                  );
                },
                id: "invoiceNumber"
              },
              {
                title: () => "Payment method",
                value: (row, { focus, active }) => {
                  return (
                    <Select
                      items={paymentMethods}
                      selectedId={row.paymentMethodvalue}
                      isOpen={focus}
                      onChange={val =>
                        onFieldChange(row.id, "paymentMethodvalue", val)
                      }
                    />
                  );
                },
                id: "paymentMethod"
              },
              {
                title: () => "Amount",
                value: (row, { focus, active }) => {
                  if (active) {
                    setTimeout(() => {
                      row.amountRef.current.focus();
                    }, 50);
                  }
                  return (
                    <input
                      type="number"
                      value={row.amount}
                      className={classes.CellItem}
                      onChange={val =>
                        onInputFieldChange(
                          row.id,
                          "amount",
                          val.target.value,
                          row.amountRef,
                          active,
                          focus
                        )
                      }
                      ref={row.amountRef}
                      onFocus={moveCaretAtEnd}
                      onKeyDown={val =>
                        onKeyDownInputField(
                          val.key,
                          row.amountRef,
                          active,
                          focus
                        )
                      }
                    ></input>
                  );
                },
                id: "amount"
              },
              {
                title: () => "Invoice status",
                value: (row, { focus, active }) => {
                  return (
                    <Select
                      items={statusMethods}
                      selectedId={row.invoiceStatus}
                      isOpen={focus}
                      onChange={val =>
                        onFieldChange(row.id, "invoiceStatus", val)
                      }
                    />
                  );
                },
                id: "invoiceStatus"
              },
              {
                title: () => "Delivery person",
                value: (row, { focus, active }) => {
                  return (
                    <Select
                      items={deliveryPersons}
                      selectedId={row.deliveryPerson}
                      isOpen={focus}
                      onChange={val =>
                        onFieldChange(row.id, "deliveryPerson", val)
                      }
                    />
                  );
                },
                id: "deliveryPerson"
              },
              {
                title: () => "Number of packs",
                value: (row, { focus, active }) => {
                  if (active) {
                    setTimeout(() => {
                      // console.log(row.packsNumberRef);
                      row.packsNumberRef.current.focus();
                    }, 50);
                  }
                  return (
                    <input
                      type="number"
                      value={row.packsNumber}
                      className={classes.CellItem}
                      onChange={val =>
                        onInputFieldChange(
                          row.id,
                          "packsNumber",
                          val.target.value,
                          row.packsNumberRef,
                          active,
                          focus
                        )
                      }
                      ref={row.packsNumberRef}
                      onFocus={moveCaretAtEnd}
                      onKeyDown={val =>
                        onKeyDownInputField(
                          val.key,
                          row.packsNumberRef,
                          active,
                          focus
                        )
                      }
                    ></input>
                  );
                },
                id: "packsNumber"
              }
            ]}
            rows={rows}
            getRowKey={row => row.id}
            blurCurrentFocus={blurCurrentFocus}
            isColumnsResizable
            rowHeight={60}
            columnWidthValues={{
              id: 4,
              invoiceNumber: 15,
              paymentMethod: 15,
              amount: 9,
              invoiceStatus: 15,
              // customer: 20,
              deliveryPerson: 12,
              packsNumber: 12
            }}
          />
        </div>
        {submitButton}
        {postButtons}
      </WhiteCard>
    </div>
  );
};

const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(mapStateToProps, {})(withRouter(LabelsGenerator));
