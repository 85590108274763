import React, { useState, useEffect } from "react";
import classes from "./UpdateInvoiceStatus.css";
import WhiteCard from "../../../components/UI/WhiteCard/WhiteCard";
import Card from "../../../components/UI/Card/Card";
import Switch from "react-switch";
import SizedBox from "../../../components/UI/SizedBox/SizedBox";
import RadioButton from "../../../components/UI/RadioButton/RadioButton";
import Divider from "../../../components/UI/Divider/Divider";
import emptyBox from "../../../assets/images/emptyBox.svg";
import useEventListener from "@use-it/event-listener";
import axios from "../../../axios";
import InvoceCard from "../InvoiceCard/InvoiceCard";
import MutanabiButton from "../../../components/UI/MutanabiButton/MutanabiButton";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import SearchInput from "../../../components/UI/SearchInput/SearchInput";

// import readXlsxFile from "read-excel-file";
import XLSX from "xlsx";
import printLabel from "./printLabel";

const UpdateInvoiceStatus = ({ auth }) => {
  const paymentMethods = [
    {
      value: "inAccount",
      label: "بالحساب"
    },
    {
      value: "cashOnDelivery",
      label: "نقدا عند التسليم"
    },
    {
      value: "chx",
      label: "شك"
    }
  ];
  const statusMethods = [
    {
      value: "issued",
      label: "Issued"
    },
    {
      value: "ofd",
      label: "Out for delivery"
    },
    {
      value: "delivered",
      label: "Delivered"
    },
    {
      value: "returned",
      label: "Returned"
    }
  ];

  const deliveryPersons = [
    {
      value: "mersal",
      label: "مرسال"
    },
    {
      value: "nidal",
      label: "نضال"
    },
    {
      value: "raaed",
      label: "رائد"
    },
    {
      value: "fadi",
      label: "فادي"
    },
    {
      value: "fadiN",
      label: "فادي نعمة"
    },
    {
      value: "muhanned",
      label: "مهند"
    },
    {
      value: "motaz",
      label: "معتز"
    },
    {
      value: "hussein",
      label: "حسين"
    },
    {
      value: "murad",
      label: "مراد"
    },
    {
      value: "mustafa",
      label: "مصطفى"
    },
    {
      value: "maha",
      label: "مها"
    },
    {
      value: "rima",
      label: "ريما"
    },
    {
      value: "ahmed",
      label: "احمد"
    },
    {
      value: "hadeel",
      label: "هديل"
    },
    {
      value: "mai",
      label: "مي"
    },
    {
      value: "hamzah",
      label: "حمزة"
    },
    {
      value: "waael",
      label: "وائل"
    },
    {
      value: "tamara",
      label: "تمارا"
    },
    {
      value: "anmar",
      label: "انمار"
    },
    {
      value: "eyad",
      label: "إياد"
    },
    {
      value: "mohammed",
      label: "محمد"
    },
    {
      value: "mamdouh",
      label: "ممدوح"
    },
    {
      value: "others",
      label: "Others"
    }
  ];

  var isChangingList = {};

  var typedText = "";
  const [statusMethod, setStatusMethod] = useState("returned");
  const [deliveryPerson, setDeliveryPerson] = useState("mersal");
  const [isAdd, setIsAdd] = useState(true);
  const nitems = [];
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  const handleSearchBoxChange = event => {
    if (event.target.value.includes("^")) {
      const box = document.getElementById("searchBox");
      box.blur();
      return;
    }
    setSearchText(event.target.value);
  };

  const handleSearchBox = async () => {
    setIsLoading(true);
    try {
      var result = await axios.get(`/invoices/search/text?text=${searchText}`);
      result.data.forEach(invoice => {
          handleAddItem(invoice.invoiceId)
      });
      console.log(result.data)
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleSave = () => {
    setIsLoading(true);
    axios
      .post("invoices/save", { modefier: auth.user.id, items: items })
      .then(result => {
        setIsLoading(false);
        if (result.data.success) {
          alert("invoices saved successfully");
          setItems([]);
        } else {
          alert("Error occured please check internet connection and try again");
        }
      });
  };

  const handleAddItem = invoiceId => {
    const found = items.find(item => item.invoiceId == invoiceId);
    if (found) {
      return;
    }

    const newItem = {
      invoiceNumber: "",
      invoiceId: invoiceId,
      status: statusMethod,
      paymentMethod: "",
      invoiceSystem: invoiceId.substring(0, 6) == "105308" ? "ct" : "mersal",
      customer: {},
      customerName: "",
      amount: "",
      IssuanceDate: "",
      deliveryPerson: "",
      issuer: "",
      text: "",
      packsNumber: ""
    };
    const newItems = [...items];
    newItems.push(newItem);
    nitems.push(newItem);
    setItems(newItems);
  };
  const handleRemoveItem = invoiceId => {
    const found = items.findIndex(item => item.invoiceId == invoiceId);
    if (found == -1) {
      return;
    }
    const newitems = [...items];
    newitems.splice(found, 1);
    nitems.splice(found, 1);
    setItems(newitems);
  };

  const handleKeypress = event => {
    // console.log(event.key)
    // if enter pressed
    if (event.keyCode === 13 && typedText.includes("^")) {
      var stringArray = typedText.split("^");
      if (isAdd) {
        handleAddItem(stringArray[stringArray.length - 1]);
      } else {
        handleRemoveItem(stringArray[stringArray.length - 1]);
      }
      typedText = "";
    }
    if (event.key == "Shift") {
      return;
    }
    typedText = typedText + event.key;
  };
  useEventListener("keydown", handleKeypress);

  const handlecustomerText = (text, index) => {
    const nItems = [...items];
    nItems[index].text = text;
    isChangingList[nItems[index].invoiceId] = true;
    setItems(nItems);
  };
  const handlePaymentMethodChange = (event, index) => {
    const nItems = [...items];
    nItems[index].paymentMethod = event.target.value;
    isChangingList[nItems[index].invoiceId] = true;
    setItems(nItems);
  };
  const handleDeliveryPersonChange = (event, index) => {
    const nItems = [...items];
    nItems[index].deliveryPerson = event.target.value;
    isChangingList[nItems[index].invoiceId] = true;
    setItems(nItems);
  };

  const handleStatusMethodChange = (event, index) => {
    const nItems = [...items];
    nItems[index].status = event.target.value;
    isChangingList[nItems[index].invoiceId] = true;
    setItems(nItems);
  };

  const handleSwitchChange = isAdd => {
    setIsAdd(!isAdd);
  };
  const handleAddButton = () => {
    setIsAdd(true);
  };
  const handleRemoveButton = () => {
    setIsAdd(false);
  };

  const handleInvoiceNumberChange = (event, index) => {
    const nItems = [...items];
    nItems[index].invoiceNumber = event.target.value;
    isChangingList[nItems[index].invoiceId] = true;
    setItems(nItems);
  };
  const handleCustomerSelection = (customer, index) => {
    const nItems = [...items];
    nItems[index].customer = customer;
    nItems[index].customerName = customer.name;
    console.log(nItems[index].customerName);
    isChangingList[nItems[index].invoiceId] = true;
    setItems(nItems);
  };
  const handleAmountChange = (event, index, itemsl) => {
    const nItems = [...items];
    nItems[index].amount = event.target.value;
    isChangingList[nItems[index].invoiceId] = true;
    setItems(nItems);
  };

  const handlePacksChange = (event, index, itemsl) => {
    const nItems = [...items];
    nItems[index].packsNumber = event.target.value;
    isChangingList[nItems[index].invoiceId] = true;
    setItems(nItems);
  };

  const handlePrint = index => {
    const item = items[index];
    printLabel(
      item.invoiceId,
      item.invoiceNumber,
      item.customerName,
      item.packsNumber,
      item.customer.city ? item.customer.city : item.customer.address,
      item.paymentMethod,
      item.IssuanceDate
    );
  };

  const deleteItem = async (index) =>{
    const newitems = [...items];
    const item = newitems[index] 

    console.log("handle delete")
    try {
    var result = await axios.delete(`/invoices/invoice?invoiceId=${item.invoiceId}`);
    newitems.splice(index, 1);
    nitems.splice(index, 1);
    setItems(newitems);
      console.log(result.data)
    } catch (error) {
      console.log(error)
    }
  }

  const statusOptions = (
    <div className={classes.statusOptions}>
      <Divider color="black" />
      <h2>Default status</h2>
      <div className={classes.RadioButtonList}>
        <RadioButton
          checked={statusMethod === "issued"}
          handleClick={() => {
            setStatusMethod("issued");
          }}
        >
          Issued
        </RadioButton>
        <RadioButton
          checked={statusMethod === "ofd"}
          handleClick={() => {
            setStatusMethod("ofd");
          }}
        >
          Out for delivery
        </RadioButton>
        <RadioButton
          checked={statusMethod === "delivered"}
          handleClick={() => {
            setStatusMethod("delivered");
          }}
        >
          Delivered
        </RadioButton>
        <RadioButton
          checked={statusMethod === "returned"}
          handleClick={() => {
            setStatusMethod("returned");
          }}
        >
          Returned
        </RadioButton>
      </div>
      <Divider color="black" />
    </div>
  );

  const emptyState = (
    <div className={classes.emptyState}>
      <img src={emptyBox} alt="empty state" title="empty state" />
      <h2>Start adding invoices</h2>
      <p>
        Start adding invoices by scanning the QR or Barcodes of the invoices
      </p>
    </div>
  );

  const motanabiButton =
    items.length < 1 ? (
      <div></div>
    ) : (
      <div className={classes.Button}>
        <MutanabiButton isLoading={isLoading} click={handleSave}>
          Save
        </MutanabiButton>
      </div>
    );

  const ItemsComponent =
    items.length < 1 ? (
      emptyState
    ) : (
      <div className={classes.InvoicesCards}>
        <h2>Invoices</h2>
        <ul>
          {items.map((item, index) => {
            return (
              <InvoceCard
                key={item.invoiceId}
                item={item}
                index={index}
                handleInvoiceNumberChange={handleInvoiceNumberChange}
                handleCustomerSelection={handleCustomerSelection}
                handlecustomerText={handlecustomerText}
                handleAmountChange={handleAmountChange}
                handlePaymentMethodChange={handlePaymentMethodChange}
                paymentMethods={paymentMethods}
                handleStatusMethodChange={handleStatusMethodChange}
                handleDeliveryPersonChange={handleDeliveryPersonChange}
                handlePrint={handlePrint}
                deleteItem={deleteItem}
                handlePacksChange={handlePacksChange}
                deliveryPersons={deliveryPersons}
                statusMethods={statusMethods}
                items={items}
                setItems={setItems}
                isChangingList={isChangingList}
              ></InvoceCard>
            );
          })}
          {/* <AutoSizer>
            {({ height, width }) => ( */}
          {/* <List
                height={items.length*30}
                itemCount={items.length}
                itemSize={30}
                width={1000}
              >
              {({ index, style }) => 
                <InvoceCard
                  key={items[index].invoiceId}
                  item={items[index]}
                  index={index}
                  handleInvoiceNumberChange={handleInvoiceNumberChange}
                  handleCustomerSelection={handleCustomerSelection}
                  handlecustomerText={handlecustomerText}
                  handleAmountChange={handleAmountChange}
                  handlePaymentMethodChange={handlePaymentMethodChange}
                  paymentMethods={paymentMethods}
                  handleStatusMethodChange={handleStatusMethodChange}
                  handleDeliveryPersonChange={handleDeliveryPersonChange}
                  deliveryPersons={deliveryPersons}
                  statusMethods={statusMethods}
                  items={items}
                  setItems={setItems}
                ></InvoceCard>}
              </List> */}
          {/* ) */}
          {/* } */}
          {/* </AutoSizer> */}
        </ul>
      </div>
    );

  const handleFileChange = e => {
    const files = e.target.files;
    // console.log(files);
    const file = files[0];
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = e => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        bookVBA: true
      });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Update state */
      const letters = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];
      const arrref = ws["!ref"].split("J");
      const length = parseInt(arrref[1]);
      const dataArray = [];
      letters.forEach(letter => {
        // delete ws[letter + "1"];
        // delete ws[letter + "2"];
        // delete ws[letter + `${length}`]
        // delete ws[letter + `${length - 1}`]
        for (let index = 3; index < length - 1; index++) {
          // console.log(letter + `${index}`)
          const element = ws[letter + `${index}`];
          // console.log(element)
          if (letter == "A") {
            const barNumber = { invoiceId: element["v"] };
            dataArray.push(barNumber);
          }
          const dataElement = dataArray[index - 3];
          if (letter == "C") {
            dataElement.invoiceNumber = element["v"];
            dataElement.invoiceSystem = "mersal";
            dataElement.deliveryPerson = "mersal";
            dataElement.customer = {};
            dataElement.fromExcell = true;
            dataElement.status = statusMethod;
          }
          if (letter == "D") {
            dataElement.amount = element["v"];
          }
          if (letter == "E") {
            dataElement.paymentMethod = paymentMethods.find(
              method => method.label == element["v"]
            ).value;
          }
          if (letter == "F") {
            dataElement.packsNumber = element["v"];
          }
          if (letter == "H") {
            dataElement.address = element["v"];
          }
          if (letter == "I") {
            dataElement.customerName = element["v"];
            dataElement.text = element["v"];
          }
        }
      });
      // console.log(dataArray);
      var newItems = [...items];
      dataArray.forEach(item => {
        newItems.push(item);
        nitems.push(item);
      });
      setItems(newItems);

      /* Convert array of arrays */
      // const data = XLSX.utils.sheet_to_json(ws);
      // console.log(ws);
      // console.log(make_cols(ws['!ref']))
      // this.setState({ data: data, cols: make_cols(ws['!ref']) }, () => {
      //   console.log(JSON.stringify(this.state.data, null, 2));
      // });
    };
    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  };

  return (
    <div>
      <WhiteCard backgroundColor={"#F1F4F7"}>
        <h1>Update invoices</h1>
        <br></br>
        <div className={classes.Excell}>
          <p>Upload Excell file</p>
          <input
            type="file"
            id="input"
            name="loadXml"
            onChange={handleFileChange}
          ></input>
        </div>
        <div className={classes.Cards}>
          <div onClick={handleAddButton}>
            <Card backgroundColor={"#01ACC1"} icon={"Add"} size="medium" />
          </div>
          <SizedBox width={10} />
          <Switch
            height={28}
            width={56}
            offColor="#01ACC1"
            onColor="#C10134"
            uncheckedIcon={false}
            checkedIcon={false}
            onChange={handleSwitchChange}
            checked={!isAdd}
          />
          <SizedBox width={10} />
          <div onClick={handleRemoveButton}>
            <Card backgroundColor={"#C10134"} icon={"Remove"} size="medium" />
          </div>
        </div>
        <br></br>
        <form
          onSubmit={event => {
            handleSearchBox();
            event.preventDefault();
          }}
        >
          <div className={classes.SearchInput}>
            <SearchInput
              id="searchBox"
              className={classes.Search}
              placeholder={`or search by invoice number or id`}
              autoFocus={true}
              value={searchText}
              // handleKeyDown={inputKeyPressed}
              handleSearchBoxChange={handleSearchBoxChange}
            />
          </div>
          <div className={classes.Reset}>
            <MutanabiButton isLoading={isLoading}>Search</MutanabiButton>
          </div>
        </form>

        {statusOptions}
        {ItemsComponent}
        <br></br>
        {motanabiButton}
      </WhiteCard>
    </div>
  );
};

const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(mapStateToProps, {})(withRouter(UpdateInvoiceStatus));
