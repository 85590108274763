import naxios from "axios";
var QRCode = require("qrcode");
const qs = require("querystring");

const paymentMethods = [
  {
    id: 1,
    value: "inAccount",
    name: "بالحساب"
  },
  {
    id: 2,
    value: "cashOnDelivery",
    name: "نقدا عند التسليم"
  },
  {
    id: 3,
    value: "chx",
    name: "شك"
  }
];

const printLabel = (
  invoiceId,
  invoiceNumber,
  customerName,
  packsNumber,
  city,
  paymentMethod,
  IssuanceDate
) => {
  QRCode.toDataURL(`${invoiceId}`, { type: "terminal" }, function(err, url) {
    var todayString = "";
    var today = new Date();
    var dd = today.getDate();

    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }
    todayString = mm + "/" + dd + "/" + yyyy;

    // console.log(invoiceNumber)
    // console.log(customerName)
    // console.log(packsNumber)
    // console.log(city)
    // console.log(paymentMethod)

    const requestBody = {
      printerName: "DYMO LabelWriter 450",
      labelXml: `<?xml version="1.0" encoding="utf-8"?>
      <DieCutLabel Version="8.0" Units="twips" MediaType="Default">
      <PaperOrientation>Landscape</PaperOrientation>
      <Id>Address</Id>
      <PaperName>30252 Address</PaperName>
      <DrawCommands>
      <RoundRectangle X="0" Y="0" Width="1581" Height="5040" Rx="270" Ry="270"/>
      </DrawCommands>
      <ObjectInfo>
      <AddressObject>
      <Name>Address</Name>
      <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
      <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
      <LinkedObjectName></LinkedObjectName>
      <Rotation>Rotation0</Rotation>
      <IsMirrored>False</IsMirrored>
      <IsVariable>True</IsVariable>
      <HorizontalAlignment>Right</HorizontalAlignment>
      <VerticalAlignment>Middle</VerticalAlignment>
      <TextFitMode>ShrinkToFit</TextFitMode>
      <UseFullFontHeight>True</UseFullFontHeight>
      <Verticalized>False</Verticalized>
      <StyledText>
      <Element>
      <String>مستودع ادويه المتنبي</String>
      <Attributes>
      <Font Family="Geeza Pro" Size="14" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
      <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
      </Attributes>
      </Element>
      </StyledText>
      <ShowBarcodeFor9DigitZipOnly>False</ShowBarcodeFor9DigitZipOnly>
      <BarcodePosition>Suppress</BarcodePosition>
      <LineFonts>
      <Font Family="Geeza Pro" Size="13" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
      </LineFonts>
      </AddressObject>
      <Bounds X="3087.859" Y="57.59995" Width="1759.256" Height="387.8125"/>
      </ObjectInfo>
      <ObjectInfo>
      <ImageObject>
      <Name>Image</Name>
      <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
      <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
      <LinkedObjectName></LinkedObjectName>
      <Rotation>Rotation0</Rotation>
      <IsMirrored>False</IsMirrored>
      <IsVariable>False</IsVariable>
      <Image>${url.split(",")[1]}</Image>
      <ScaleMode>Uniform</ScaleMode>
      <BorderWidth>0</BorderWidth>
      <BorderColor Alpha="255" Red="0" Green="0" Blue="0"/>
      <HorizontalAlignment>Center</HorizontalAlignment>
      <VerticalAlignment>Center</VerticalAlignment>
      </ImageObject>
      <Bounds X="331.2" Y="57.59995" Width="1002.075" Height="1250.234"/>
      </ObjectInfo>
      <ObjectInfo>
      <TextObject>
      <Name>TEXT_1</Name>
      <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
      <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
      <LinkedObjectName></LinkedObjectName>
      <Rotation>Rotation0</Rotation>
      <IsMirrored>False</IsMirrored>
      <IsVariable>False</IsVariable>
      <HorizontalAlignment>Right</HorizontalAlignment>
      <VerticalAlignment>Middle</VerticalAlignment>
      <TextFitMode>ShrinkToFit</TextFitMode>
      <UseFullFontHeight>True</UseFullFontHeight>
      <Verticalized>False</Verticalized>
      <StyledText>
      <Element>
      <String>المستلم</String>
      <Attributes>
      <Font Family="Geeza Pro" Size="13" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
      <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
      </Attributes>
      </Element>
      <Element>
      <String>: </String>
      <Attributes>
      <Font Family="Lucida Grande" Size="13" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
      <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
      </Attributes>
      </Element>
      <Element>
      <String>${customerName}</String>
      <Attributes>
      <Font Family="Geeza Pro" Size="13" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
      <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
      </Attributes>
      </Element>
      </StyledText>
      </TextObject>
      <Bounds X="1482.422" Y="407.8125" Width="3434.844" Height="395.625"/>
      </ObjectInfo>
      <ObjectInfo>
      <TextObject>
      <Name>TEXT_2</Name>
      <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
      <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
      <LinkedObjectName></LinkedObjectName>
      <Rotation>Rotation0</Rotation>
      <IsMirrored>False</IsMirrored>
      <IsVariable>False</IsVariable>
      <HorizontalAlignment>Right</HorizontalAlignment>
      <VerticalAlignment>Middle</VerticalAlignment>
      <TextFitMode>ShrinkToFit</TextFitMode>
      <UseFullFontHeight>True</UseFullFontHeight>
      <Verticalized>False</Verticalized>
      <StyledText>
      <Element>
      <String>عدد الطرود</String>
      <Attributes>
      <Font Family="Geeza Pro" Size="13" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
      <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
      </Attributes>
      </Element>
      <Element>
      <String>:</String>
      <Attributes>
      <Font Family="Lucida Grande" Size="13" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
      <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
      </Attributes>
      </Element>
      <Element>
      <String>${packsNumber}</String>
      <Attributes>
      <Font Family="Geeza Pro" Size="13" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
      <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
      </Attributes>
      </Element>
      </StyledText>
      </TextObject>
      <Bounds X="3315.709" Y="788.75" Width="1499.062" Height="382.9688"/>
      </ObjectInfo>
      <ObjectInfo>
      <TextObject>
      <Name>TEXT_3</Name>
      <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
      <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
      <LinkedObjectName></LinkedObjectName>
      <Rotation>Rotation0</Rotation>
      <IsMirrored>False</IsMirrored>
      <IsVariable>False</IsVariable>
      <HorizontalAlignment>Left</HorizontalAlignment>
      <VerticalAlignment>Middle</VerticalAlignment>
      <TextFitMode>ShrinkToFit</TextFitMode>
      <UseFullFontHeight>True</UseFullFontHeight>
      <Verticalized>False</Verticalized>
      <StyledText>
      <Element>
      <String>${invoiceNumber}</String>
      <Attributes>
      <Font Family="Helvetica" Size="13" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
      <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
      </Attributes>
      </Element>
      </StyledText>
      </TextObject>
      <Bounds X="331.2" Y="1168.191" Width="1037.188" Height="324.6094"/>
      </ObjectInfo>
      <ObjectInfo>
      <TextObject>
      <Name>TEXT_4</Name>
      <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
      <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
      <LinkedObjectName></LinkedObjectName>
      <Rotation>Rotation0</Rotation>
      <IsMirrored>False</IsMirrored>
      <IsVariable>False</IsVariable>
      <HorizontalAlignment>Right</HorizontalAlignment>
      <VerticalAlignment>Middle</VerticalAlignment>
      <TextFitMode>ShrinkToFit</TextFitMode>
      <UseFullFontHeight>True</UseFullFontHeight>
      <Verticalized>False</Verticalized>
      <StyledText>
      <Element>
      <String>المدينه</String>
      <Attributes>
      <Font Family="Geeza Pro" Size="13" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
      <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
      </Attributes>
      </Element>
      <Element>
      <String>:</String>
      <Attributes>
      <Font Family="Lucida Grande" Size="13" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
      <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
      </Attributes>
      </Element>
      <Element>
      <String>${city}</String>
      <Attributes>
      <Font Family="Geeza Pro" Size="13" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
      <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
      </Attributes>
      </Element>
      </StyledText>
      </TextObject>
      <Bounds X="1717.059" Y="811.1719" Width="1392.344" Height="336.0156"/>
      </ObjectInfo>
      <ObjectInfo>
      <TextObject>
      <Name>TEXT_5</Name>
      <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
      <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
      <LinkedObjectName></LinkedObjectName>
      <Rotation>Rotation0</Rotation>
      <IsMirrored>False</IsMirrored>
      <IsVariable>False</IsVariable>
      <HorizontalAlignment>Right</HorizontalAlignment>
      <VerticalAlignment>Middle</VerticalAlignment>
      <TextFitMode>ShrinkToFit</TextFitMode>
      <UseFullFontHeight>True</UseFullFontHeight>
      <Verticalized>False</Verticalized>
      <StyledText>
      <Element>
      <String>طريقه الدفع</String>
      <Attributes>
      <Font Family="Geeza Pro" Size="13" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
      <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
      </Attributes>
      </Element>
      <Element>
      <String>: </String>
      <Attributes>
      <Font Family="Lucida Grande" Size="13" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
      <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
      </Attributes>
      </Element>
      <Element>
      <String>${
        paymentMethods.find(method => method.value == paymentMethod).name
      }</String>
      <Attributes>
      <Font Family="Geeza Pro" Size="13" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
      <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
      </Attributes>
      </Element>
      </StyledText>
      </TextObject>
      <Bounds X="1685.547" Y="1183.906" Width="3125.312" Height="308.8938"/>
      </ObjectInfo>
      <ObjectInfo>
      <TextObject>
      <Name>TEXT</Name>
      <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
      <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
      <LinkedObjectName></LinkedObjectName>
      <Rotation>Rotation0</Rotation>
      <IsMirrored>False</IsMirrored>
      <IsVariable>False</IsVariable>
      <HorizontalAlignment>Left</HorizontalAlignment>
      <VerticalAlignment>Middle</VerticalAlignment>
      <TextFitMode>ShrinkToFit</TextFitMode>
      <UseFullFontHeight>True</UseFullFontHeight>
      <Verticalized>False</Verticalized>
      <StyledText>
      <Element>
      <String>${todayString}</String>
      <Attributes>
      <Font Family="Lucida Grande" Size="13" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
      <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
      </Attributes>
      </Element>
      </StyledText>
      </TextObject>
      <Bounds X="1539.012" Y="57.59995" Width="1472.344" Height="367.1875"/>
      </ObjectInfo>
      </DieCutLabel>
      `,
      printParamsXml: "",
      labelSetXml: ""
    };

    console.log(requestBody.labelXml)

    const config = {
      headers: {
        Accept: "*/*",
        "Content-Type": "application/x-www-form-urlencoded",
        Connection: "keep-alive"
      }
    };

    naxios
      .post(
        "https://localhost:41951/DYMO/DLS/Printing/PrintLabel",
        qs.stringify(requestBody),
        config
      )
      .then(result => {
        // Do somthing
      })
      .catch(err => {
        console.log(err);
        // Do somthing
      });
  });
};

export default printLabel;
