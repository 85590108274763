import React, { useState } from "react";
import useEventListener from "@use-it/event-listener";
import axios from "../../../axios";
import classes from "./InvoiceReports.css";
import qrGif from "../../../assets/images/qrscan.gif";
import TableSetting from "./TableSettings";
import MUIDataTable from "mui-datatables";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MutanabiButton from "../../../components/UI/MutanabiButton/MutanabiButton";
import RadioButton from "../../../components/UI/RadioButton/RadioButton";
import SearchInput from "../../../components/UI/SearchInput/SearchInput";
import CheckBox from "../../../components/UI/CheckBox/CheckBox";
import DatePicker from "../../../components/UI/DatePicker/DatePicker";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
const FileDownload = require("js-file-download");

const InvoiceReports = ({ auth }) => {
  const today = new Date();
  const dateToString = date => {
    const stringDate =
      date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getDate()).slice(-2);
    return stringDate;
  };
  const [fromDate, setFromDate] = useState(dateToString(today));
  const [toDate, setToDate] = useState(dateToString(today));

  const statusMethods = [
    {
      value: "issued",
      label: "Issued"
    },
    {
      value: "ofd",
      label: "Out for delivery"
    },
    {
      value: "delivered",
      label: "Delivered"
    },
    {
      value: "returned",
      label: "Returned"
    }
  ];
  const paymentMethods = [
    {
      value: "inAccount",
      label: "بالحساب"
    },
    {
      value: "cashOnDelivery",
      label: "نقداً عند التسليم"
    },
    {
      value: "chx",
      label: "شيك"
    }
  ];

  const deliveryPersons = [
    {
      value: "any",
      label: "جميع الخيارات"
    },
    {
      value: "mersal",
      label: "مرسال"
    },
    {
      value: "nidal",
      label: "نضال"
    },
    {
      value: "raaed",
      label: "رائد"
    },
    {
      value: "fadi",
      label: "فادي"
    },
    {
      value: "fadiN",
      label: "فادي نعمة"
    },
    {
      value: "muhanned",
      label: "مهند"
    },
    {
      value: "motaz",
      label: "معتز"
    },
    {
      value: "hussein",
      label: "حسين"
    },
    {
      value: "murad",
      label: "مراد"
    },
    {
      value: "mustafa",
      label: "مصطفى"
    },
    {
      value: "maha",
      label: "مها"
    },
    {
      value: "rima",
      label: "ريما"
    },
    {
      value: "ahmed",
      label: "احمد"
    },
    {
      value: "hadeel",
      label: "هديل"
    },
    {
      value: "mai",
      label: "مي"
    },
    {
      value: "hamzah",
      label: "حمزة"
    },
    {
      value: "waael",
      label: "وائل"
    },
    {
      value: "tamara",
      label: "تمارا"
    },
    {
      value: "anmar",
      label: "انمار"
    },
    {
      value: "eyad",
      label: "إياد"
    },
    {
      value: "mohammed",
      label: "محمد"
    },
    {
      value: "mamdouh",
      label: "ممدوح"
    },
    {
      value: "others",
      label: "Others"
    }
  ];

  const columns = [
    {
      name: "invoiceNumber",
      label: "Invoice number",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "invoiceId",
      label: "invoice Id",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "paymentMethod",
      label: "Payment method",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "invoiceSystem",
      label: "Invoice System",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "customer",
      label: "Customer",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "deliveryPerson",
      label: "Delivery Person",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "IssuanceDate",
      label: "Issuance Date",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "view",
      // label: "view",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          // <FormControlLabel
          //   value={value}
          //   control={<TextField value={value} />}
          //   onChange={event => updateValue(event.target.value)}
          // />
          <p>non</p>
        )
      }
    }
  ];

  const [selectedStatusMethods, setSelectedStatusMethods] = useState([
    "issued"
  ]);
  const [selectedSystems, setselectedSystems] = useState([]);
  const [selectedPaymentMethods, setselectedPaymentMethods] = useState([]);
  const [selectedDeliveryPerson, setSelectedDeliveryPerson] = useState("any");

  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isInit, setIsInit] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isPdfLoading,setIsPdfLoading] = useState(false)

  var typedText = "";

  const fromDatePickerChangeHandler = event => {
    setFromDate(event.target.value);
  };
  const toDatePickerChangeHandler = event => {
    setToDate(event.target.value);
  };

  const handleSearchBoxChange = event => {
    if (event.target.value.includes("^")) {
      const box = document.getElementById("searchBox");
      box.blur();
      return;
    }
    setSearchText(event.target.value);
  };

  const handleQrScan = event => {
    if (event.keyCode === 13 && typedText.includes("^")) {
      var stringArray = typedText.split("^");
      handleSearch(true, stringArray[stringArray.length - 1]);
    }
    typedText = typedText + event.key;
  };

  const handleDeliveryPersonChange = event => {
    setSelectedDeliveryPerson(event.target.value);
  };

  const handleSearch = (isQR, value) => {
    setIsLoading(true);
    if (isInit) {
      setIsInit(false);
    }
    const apiSearch = uri => {
      axios
        .get(uri)
        .then(results => {
          if (results.data.error) {
            setIsLoading(false);
            return;
          }
          setIsLoading(false);
          if (results.data[0]) {
            const newData = results.data.map(result => {
              const status = statusMethods.find(
                method => method.value == result.status
              );
              const payment = paymentMethods.find(
                method => method.value == result.paymentMethod
              );
              var object = {
                invoiceNumber: result.invoiceNumber,
                invoiceId: result.invoiceId,
                status: status ? status.label : "",
                paymentMethod: payment ? payment.label : "",
                invoiceSystem: result.invoiceSystem,
                customer: result.customer
                  ? result.customer.name
                  : result.customerName
                  ? result.customerName
                  : "",
                amount: result.amount ? result.amount : "",
                deliveryPerson: result.deliveryPerson
                  ? deliveryPersons.find(
                      person => person.value == result.deliveryPerson
                    ).label
                  : "",
                IssuanceDate: result.IssuanceDate
              };
              return object;
            });
            setData(newData);
          } else {
            setData([]);
          }
        })
        .catch(err => console.log(err));
    };

    if (isQR) {
      apiSearch(`/invoices/search/text?text=${value}`);
      return;
    }
    if (searchText != "") {
      apiSearch(`/invoices/search/text?text=${searchText}`);
    } else {
      var queryData = {
        statuses: selectedStatusMethods,
        systems: selectedSystems,
        paymentMethods: selectedPaymentMethods,
        fromDate: fromDate,
        toDate: toDate,
        deliveryPerson: selectedDeliveryPerson
      };
      if (selectedDeliveryPerson == "any") {
        // const persons = deliveryPersons.map(person=>{
        //   return person.value
        // })
        // queryData.deliveryPerson = persons
        delete queryData.deliveryPerson;
      }
      axios
        .post("/invoices/search/combined", queryData)
        .then(results => {
          if (results.data.error) {
            setIsLoading(false);
            return;
          }
          setIsLoading(false);
          if (results.data[0]) {
            const newData = results.data.map(result => {
              const status = statusMethods.find(
                method => method.value == result.status
              );
              const payment = paymentMethods.find(
                method => method.value == result.paymentMethod
              );

              var object = {
                invoiceNumber: result.invoiceNumber,
                invoiceId: result.invoiceId,
                status: status ? status.label : "",
                paymentMethod: payment ? payment.label : "",
                invoiceSystem: result.invoiceSystem,
                customer: result.customer ? result.customer.name : "",
                amount: result.amount ? result.amount : "",
                deliveryPerson: result.deliveryPerson
                  ? deliveryPersons.find(
                      person => person.value == result.deliveryPerson
                    ).label
                  : "",
                IssuanceDate: result.IssuanceDate
              };
              return object;
            });
            setData(newData);
          } else {
            setData([]);
          }
        })
        .catch(err => console.log(err));
    }
  };
  useEventListener("keydown", handleQrScan);

  const handleMethodRadioSelection = value => {
    setSearchText("");

    setSelectedStatusMethods([value]);
    // if (selectedStatusMethods.includes(value)) {
    //   const index = selectedStatusMethods.findIndex(item => item == value);
    //   const updatedlist = [...selectedStatusMethods];
    //   updatedlist.splice(index, 1);
    //   setSelectedStatusMethods(updatedlist);
    // } else {
    //   const updatedlist = [...selectedStatusMethods];
    //   updatedlist.push(value);
    //   setSelectedStatusMethods(updatedlist);
    // }
  };
  const statusOptions = (
    <div className={classes.statusOptions}>
      <h2>Status</h2>
      <div className={classes.RadioContainer}>
        <div className={classes.RadioButtonList}>
          {statusMethods.map(method => (
            <RadioButton
              weight={"bold"}
              key={method.value}
              checked={selectedStatusMethods.includes(method.value)}
              handleClick={() => handleMethodRadioSelection(method.value)}
            >
              {method.label}
            </RadioButton>
          ))}
        </div>
        <br></br>
      </div>
      <br></br>
    </div>
  );

  const handleSystemRadioSelection = value => {
    setSearchText("");
    if (selectedSystems.includes(value)) {
      const index = selectedSystems.findIndex(item => item == value);
      const updatedlist = [...selectedSystems];
      updatedlist.splice(index, 1);
      setselectedSystems(updatedlist);
    } else {
      const updatedlist = [...selectedSystems];
      updatedlist.push(value);
      setselectedSystems(updatedlist);
    }
  };

  const systemOptions = (
    <div className={classes.statusOptions}>
      <h2> Search parameters</h2>
      <div className={classes.RadioContainer}>
        <div className={classes.RadioButtonList}>
          <CheckBox
            checked={selectedSystems.includes("mersal")}
            handleClick={() => handleSystemRadioSelection("mersal")}
          >
            Mersal
          </CheckBox>
          <CheckBox
            checked={selectedSystems.includes("ct")}
            handleClick={() => handleSystemRadioSelection("ct")}
          >
            CT
          </CheckBox>
          {/* <div className={classes.RadioButtonList}> */}
          {paymentMethods.map(method => (
            <CheckBox
              key={method.value}
              checked={selectedPaymentMethods.includes(method.value)}
              handleClick={() =>
                handlePaymentMethodRadioSelection(method.value)
              }
            >
              {method.label}
            </CheckBox>
          ))}
          {/* </div> */}
        </div>
        <div className={classes.persons}>
          <TextField
            id="standard-required-delivery"
            select
            label="Delivery person"
            margin="normal"
            type="text"
            className={classes.SubTextField}
            // required
            value={selectedDeliveryPerson}
            onChange={event => handleDeliveryPersonChange(event)}
            // helperText="Please select delivery person"
            //   onChange={handleCityInput}
          >
            {deliveryPersons.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className={classes.Reset}>
          <MutanabiButton
            width={100}
            height={40}
            fontSize={14}
            click={() => {
              setselectedSystems([]);
              setselectedPaymentMethods([]);
            }}
          >
            Clear
          </MutanabiButton>
        </div>
      </div>
    </div>
  );

  const handlePaymentMethodRadioSelection = value => {
    setSearchText("");
    if (selectedPaymentMethods.includes(value)) {
      const index = selectedPaymentMethods.findIndex(item => item == value);
      const updatedlist = [...selectedPaymentMethods];
      updatedlist.splice(index, 1);
      setselectedPaymentMethods(updatedlist);
    } else {
      const updatedlist = [...selectedPaymentMethods];
      updatedlist.push(value);
      setselectedPaymentMethods(updatedlist);
    }
  };

  const downloadPdf = () => {
    // data
    setIsPdfLoading(true)
    const headers = columns.map(column => {
      return { id: column.name, header: column.label, width: 50 };
    });
    headers.pop();
    const parms = { headers: headers, body: data };
    axios.post("/pdfreport", parms, { responseType: "blob" }).then(response => {
      setIsPdfLoading(false)
      FileDownload(response.data, "report.pdf");
    });
  };

  const paymentOptions = (
    <div className={classes.statusOptions}>
      <h2>Payment method</h2>
      <div className={classes.RadioContainer}>
        {/* <div className={classes.RadioButtonList}>
          {paymentMethods.map(method => (
            <CheckBox
              key={method.value}
              checked={selectedPaymentMethods.includes(method.value)}
              handleClick={() =>
                handlePaymentMethodRadioSelection(method.value)
              }
            >
              {method.label}
            </CheckBox>
          ))}
        </div> */}
        <div className={classes.Reset}>
          <MutanabiButton
            width={100}
            height={40}
            fontSize={14}
            click={() => setselectedPaymentMethods([])}
          >
            reset
          </MutanabiButton>
        </div>
      </div>
    </div>
  );

  const searchTable = !isInit ? (
    <div>
      <div className={classes.Print}>
        <MutanabiButton
          width={150}
          height={40}
          fontSize={14}
          isLoading={isPdfLoading}
          click={() => downloadPdf()}
        >
          Download pdf
        </MutanabiButton>
      </div>
      <MUIDataTable
        title={"Search results"}
        data={data}
        columns={columns}
        options={TableSetting.options}
        className={classes.Table}
      />
    </div>
  ) : (
    <div className={classes.emptyState}>
      <img src={qrGif} alt="empty state" title="empty state" />
      <h2>Start searching invoices</h2>
      <p>
        Start searching invoices by above parameters, number, id, or scanning
        the QR or Barcodes of the invoices
      </p>
    </div>
  );

  return (
    <div className={classes.Container}>
      {/* <WhiteCard> */}
      <h1>invoices reports</h1>
      {statusOptions}
      {systemOptions}

      <form
        className={classes.Form}
        onSubmit={event => {
          handleSearch();
          event.preventDefault();
        }}
      >
        <div>
          <div className={classes.DatePickers}>
            {/* <div className={classes.statusOptions}> */}
            <DatePicker
              min="1899-01-01"
              max={toDate}
              text="From"
              value={fromDate}
              onChange={fromDatePickerChangeHandler}
            />
            <DatePicker
              min={fromDate}
              max={dateToString(today)}
              value={toDate}
              text="To"
              onChange={toDatePickerChangeHandler}
            />
          </div>
          {/* </div> */}
          <div className={classes.SearchInput}>
            <SearchInput
              id="searchBox"
              className={classes.Search}
              placeholder={`or search by invoice number or id`}
              autoFocus={true}
              value={searchText}
              // handleKeyDown={inputKeyPressed}
              handleSearchBoxChange={handleSearchBoxChange}
            />
          </div>
        </div>

        <div className={classes.Reset}>
          <MutanabiButton isLoading={isLoading}>Search</MutanabiButton>
        </div>
      </form>
      {searchTable}
      {/* </WhiteCard> */}
    </div>
  );
};

const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(mapStateToProps, {})(withRouter(InvoiceReports));
