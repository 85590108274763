import React from "react";
import classes from "./CheckBox.css";

const CheckBox = (props) => {
  return (
    <label className={classes.RadioContainer}>
      <p>{props.children}</p>
      <input
        type="checkbox"
        
        checked={props.checked}
        onClick={props.handleClick}
        readOnly
      />
      <span className={classes.Checkmark}>
        <span className={classes.Node}></span>
      </span>
    </label>
  );
};

export default CheckBox